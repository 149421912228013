var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReceivingAddress" },
    [
      _c("h3", [_vm._v("收货地址")]),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.setAdress()
                }
              }
            },
            [_vm._v("新增收货地址")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              align: "center",
              label: "收货人姓名"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              "header-align": "center",
              align: "center",
              label: "收货人手机"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "所在地区"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.provinceName) +
                          _vm._s(scope.row.cityName) +
                          _vm._s(scope.row.areaName)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              "header-align": "center",
              align: "center",
              label: "详细地址"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "默认地址"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0
                      },
                      on: {
                        change: function($event) {
                          return _vm.setDefault(scope.row.id)
                        }
                      },
                      model: {
                        value: scope.row.isDefault,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isDefault", $$v)
                        },
                        expression: "scope.row.isDefault"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.setAdress(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.delAdress(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "page",
        attrs: {
          "current-page": _vm.page,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.limit,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setType == "add" ? "新增收货地址" : "修改收货地址",
            visible: _vm.showaddAdress,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showaddAdress = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "status-icon": "",
                "label-width": "150px",
                model: _vm.form,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收货人姓名：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收货人手机：", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在地区：", prop: "selectedOptions" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      size: "large",
                      options: _vm.options,
                      "collapse-tags": ""
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.form.selectedOptions,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "selectedOptions", $$v)
                      },
                      expression: "form.selectedOptions"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址：", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      rows: 5,
                      maxlength: "128",
                      placeholder: "街道门牌号等信息"
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showaddAdress = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }