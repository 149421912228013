<template>
  <div class='ReceivingAddress'>
    <h3>收货地址</h3>
    <div align='right' style="margin-bottom: 20px"> 
        <el-button type="primary" plain size="mini" @click="setAdress()">新增收货地址</el-button>
    </div>
    <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="收货人姓名">
          </el-table-column>
          <el-table-column
            prop="phone"
            header-align="center"
            align="center"
            label="收货人手机">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="所在地区">
            <template slot-scope="scope">
              <span>{{ scope.row.provinceName }}{{ scope.row.cityName }}{{ scope.row.areaName }}</span>
            </template>
          </el-table-column>
           <el-table-column
            prop="address"
            header-align="center"
            align="center"
            label="详细地址">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="默认地址">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isDefault"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="setDefault(scope.row.id)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="setAdress(scope.row.id)">修改</el-button>
              <el-button type="text" size="small" @click="delAdress(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <el-dialog
      :title="setType == 'add' ? '新增收货地址' : '修改收货地址'"
      :visible.sync="showaddAdress"
      width="50%">
    <el-form  status-icon  label-width="150px" :model="form" ref="form" :rules="rules">
      <el-form-item label="收货人姓名：" prop="name">
        <el-input v-model="form.name" maxlength="20"></el-input>
      </el-form-item>
        <el-form-item label="收货人手机：" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="所在地区：" prop="selectedOptions">
        <el-cascader
          size="large"
          :options="options"
          v-model="form.selectedOptions"
          @change="handleChange"
          collapse-tags
        >
      </el-cascader>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input
        v-model="form.address"
          type="textarea"
          resize="none"
          :rows="5"
          maxlength="128"
          placeholder="街道门牌号等信息">
        </el-input>
      </el-form-item>
    </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showaddAdress = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryAddressByPage, saveAddress, setDefaultAddress, getEditAddress, updateAddress, deleteAddress } from '@/api/userInfo'
import { regionData, CodeToText } from 'element-china-area-data'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      showaddAdress:false,
      tableData: [],
      page: 1,
      limit: 10,
      totalPage: 0,
      form: {
        name: '',
        phone: '',
        address: '',
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        areaCode: '',
        areaName: '',
        selectedOptions: [],
      },
      options: regionData,
      setType: '',
      rules: {
        name: [
            { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          ],
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        selectedOptions: [
          { type: 'array', required: true, message: '请选择所在地区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
      }
    }
  },
  mounted () {
    this.getAddress()
  },
  watch: {
    showaddAdress(newVal) {
      if(!newVal) {
        this.form = {
          name: '',
          phone: '',
          address: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: '',
          areaCode: '',
          areaName: '',
          selectedOptions: []
        }
      }
    }
  },
  methods: {
    // 查询收货地址
    async getAddress () {
      const res = await queryAddressByPage({limit: this.limit, page: this.page})
      if(res.code == 0) {
        this.tableData = res.data.list
        this.totalPage = res.data.totalCount
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.limit = val
      this.page = 1
      this.getAddress()
    },
    // 当前页
    currentChangeHandle (val) {
      this.page = val
      this.getAddress()
    },
    // 地区
    handleChange (val) {
      this.form.provinceCode = val[0]
      this.form.cityCode = val[1]
      this.form.areaCode = val[2]
      this.form.provinceName = CodeToText[val[0]]
      this.form.cityName = CodeToText[val[1]]
      this.form.areaName = CodeToText[val[2]]
    },
    // 提交
    submit () {
       this.$refs.form.validate(async (valid) => {
          if (valid) {
            let res
            if(this.setType == 'add') {
              res = await saveAddress(this.form)
            }
            else {
              res = await updateAddress(this.form)
            }
            if(res.code == 0) {
              this.showaddAdress = false
              this.$message.success('提交成功')
              this.sizeChangeHandle()
            }
          }
        });
    },
    // 设置默认地址
    async setDefault (id) {
      const res = await setDefaultAddress({id})
      if(res.code == 0) {
        this.$message.success('设置成功')
        this.sizeChangeHandle()
      }
    },
    // 新增/编辑
    async setAdress (id) {
      if(id) {
        this.setType = 'edit'
        const res = await getEditAddress({id})
        if(res.code == 0) {
          this.form = res.data
          this.form.id = id
          this.form.selectedOptions = res.data.areaCode ? [res.data.provinceCode, res.data.cityCode, res.data.areaCode] : [res.data.provinceCode, res.data.cityCode]
        }
      }else {
        this.setType = 'add'
      }
      this.showaddAdress = true
    },
    // 删除
    delAdress (id) {
        this.$confirm(`确定删除吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async () => {
          const res = await deleteAddress({id})
          if(res.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.sizeChangeHandle() 
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        }).catch(() => {})
      },
  }
}
</script>

<style lang="scss" scoped>
.ReceivingAddress{
  padding: 30px;
  background-color: white;
  .page {
    margin-top: 50px;
    text-align: right;
  }
}
</style>